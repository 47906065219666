













































































































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {}
           })
export default class PageFooter extends Vue {
  @Prop({default: 2018}) protected year!: number;
}
