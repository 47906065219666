import { render, staticRenderFns } from "./PageFooter.vue?vue&type=template&id=55a17670&scoped=true&"
import script from "./PageFooter.vue?vue&type=script&lang=ts&"
export * from "./PageFooter.vue?vue&type=script&lang=ts&"
import style0 from "./PageFooter.vue?vue&type=style&index=0&id=55a17670&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a17670",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/florian/Projects/get-a-shop-website/vuetify_getashop_promote/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib'
import { VFooter } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VFlex,VFooter,VImg,VLayout})
