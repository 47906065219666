






//TODO: transitions
import {Component, Vue} from "vue-property-decorator";
import PageFooter from '@/components/elements/PageFooter';
@Component({
             components: {
               PageFooter
             },
           })
export default class App extends Vue {

}
